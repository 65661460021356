



































import Vue from 'vue'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  components: {
    MainContentTemplate,
  },
  name: 'Error',
  props: {},
  data: () => ({}),
  computed: {
    toolbarTitle(): TranslateResult {
      return this.$t('navigation.error.toolbar.text')
    },
  },
  methods: {},
  mounted() {
    document.title = 'GTI ' + this.$t('navigation.error.title.text')
  },
})






























































import Vue from 'vue'
import debounce from '@/utils/debounce'
import { NCBI_GENE_URL, NCBI_GENE_INFO_FILE_URL } from '@/utils/apis'
import allGenesData from '@/assets/all_genes.json'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import { GeneState } from '@/types/ui-types'

export default Vue.extend({
  components: { MainContentTemplate },
  name: 'HumanGenomeDetails',
  props: {},
  data() {
    return {
      headers: [
        { text: 'Symbol', value: GeneState.SYMBOL },
        { text: 'HGNC ID', value: 'hgncId' },
        { text: 'ENSEMBL ID', value: 'ensemblId' },
        { text: 'Synonyms', value: 'synonyms' },
        { text: 'Loci', value: 'loci' },
      ],
      search: '',
      debouncedSearch: '',
      ncbiGeneURL: NCBI_GENE_URL,
      ncbiGeneInfoURL: NCBI_GENE_INFO_FILE_URL,
    }
  },
  computed: {
    allGenes() {
      return allGenesData
    },
  },
  methods: {},
  watch: {
    search: debounce(function (this: any, value: string) {
      this.debouncedSearch = value
    }, 500),
  },
  mounted() {},
})
















































































import Vue from 'vue'
import MainContentTemplate from '@/components/MainContentTemplate.vue'

export default Vue.extend({
  components: { MainContentTemplate },
  name: 'FileFormats',
  props: {},
  data() {
    return {
      csvContent: ['Gene.symbol', 'ACTA2', 'ACTC1', 'TSC2', 'VHL', 'WT1'],
      bedContent: [
        {
          line: [
            'chr1	1787293	1787413	',
            'GNB1',
            ':GNB1_chr1:1718769-1718876:275744_14961302_GNB1_chr1:1718769-1718876_1',
          ],
          validation: 'GNB1',
        },
        {
          line: ['chr1	3345058	3345178	', 'SNP', ':rs4415513'],
          validation: 'SNP ' + this.$t('help.fileFormats.notValid'),
        },
        {
          line: ['chr8	127730434	127736593	', 'MYC_upstream', ''],
          validation: 'MYC_upstream ' + this.$t('help.fileFormats.notValid'),
        },
        {
          line: [
            'chr8	127736518	127736638	',
            'MYC',
            ':MYC_chr8:128748839-128748869:275761_14977530_MYC_chr8:128748839-128748869_1',
          ],
          validation: 'MYC',
        },
        {
          line: [
            'chr12	11853597	11853717	',
            'ETV6_Fusion',
            ':ETV6:278717_15649951_ETV6_5',
          ],
          validation: 'ETV6_FUSION',
        },
        {
          line: [
            'chrX	155071469	155071589	',
            'BRCC3',
            ':BRCC3_chrX:154299802-154299925:275728_14960831_BRCC3_chrX:154299802-154299925_1',
          ],
          validation: 'BRCC3',
        },
      ],
    }
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
})


























































































import Vue from 'vue'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import GetInTouch from '@/components/help/GetInTouch.vue'
import { GITHUB_REPO, CLOUDFLARE_DEPLOY } from '@/utils/apis'

export default Vue.extend({
  components: { MainContentTemplate, GetInTouch },
  name: 'ManageApp',
  props: {},
  data() {
    return {
      githubRepo: GITHUB_REPO,
      cloudflareDeploy: CLOUDFLARE_DEPLOY,
    }
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
})








































import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import FileFormats from '@/components/help/FileFormats.vue'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'
import InstallApp from '@/components/help/InstallApp.vue'
import ManageApp from '@/components/help/ManageApp.vue'

export default Vue.extend({
  components: {
    MainContentTemplate,
    FileFormats,
    InstallApp,
    ManageApp,
  },
  name: 'Help',
  props: {},
  data: () => ({
    tabs: ['files', 'install', 'manage'],
  }),
  computed: {
    tab: {
      set(tab: string) {
        this.$router.replace({ params: { ...this.$route.params, tab } })
      },
      get(): string {
        return this.$route.params.tab ? this.$route.params.tab : 'files'
      },
    },
    background(): VuetifyThemeItem {
      return this.$vuetify.theme.themes.light.background
    },
    toolbarTitle(): TranslateResult {
      return this.$t('navigation.help.toolbar.text')
    },
  },
  methods: {},
  mounted() {
    document.title = 'GTI ' + this.$t('navigation.help.title.text')
  },
})

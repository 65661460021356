



























import Vue from 'vue'
import { ADMINS } from '@/utils/contacts'

export default Vue.extend({
  components: {},
  name: 'GetInTouch',
  props: {},
  data() {
    return {
      admins: ADMINS,
    }
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
})

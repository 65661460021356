









































import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import BuildExploreInstitutions from '@/components/manage/BuildExploreInstitutions.vue'
import BuildExplorePanels from '@/components/explore/BuildExplorePanels.vue'
import HumanGenomeDetails from '@/components/explore/HumanGenomeDetails.vue'
import MainContentTemplate from '@/components/MainContentTemplate.vue'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'

export default Vue.extend({
  components: {
    BuildExploreInstitutions,
    BuildExplorePanels,
    HumanGenomeDetails,
    MainContentTemplate,
  },
  name: 'Explore',
  props: {},
  data: () => ({
    tabs: ['panels', 'institutions', 'genome'],
  }),
  computed: {
    tab: {
      set(tab: string) {
        const item = '0'
        this.$router.replace({ params: { ...this.$route.params, tab, item } })
      },
      get(): string {
        return this.$route.params.tab ? this.$route.params.tab : 'panels'
      },
    },
    background(): VuetifyThemeItem {
      return this.$vuetify.theme.themes.light.background
    },
    toolbarTitle(): TranslateResult {
      return this.$t('navigation.explore.toolbar.text')
    },
  },
  methods: {},
  mounted() {
    document.title = 'GTI ' + this.$t('navigation.explore.title.text')
  },
})
